.project {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.project h1 {
  color: #004e98;
  font-size: 35px;
}
.project img {
  width: 500px;
  border-radius: 10px;
}

.project p {
  font-size: 30px;
  color: #333;
  margin-top: 50px;
}

.project svg {
  font-size: 60px;
  color: #3e497a;
}

.project-description {
  width: 60%;
  margin: auto;
  text-align: center;
}

.back-button {
  height: 75px;
  color: white;
}

.link-text {
  color: white;
  text-decoration: none;
}

@media only screen and (max-width: 500px) {
  .project h1 {
    font-size: 25px;
  }

  .project img {
    width: 60%;
    margin-top: 30px;
  }

  .project p {
    font-size: 20px;
    margin-top: 30px;
    width: 80%;
  }
}
