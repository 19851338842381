.resume-button {
  height: 75px;
}

.button-container {
  margin: 2rem;
}

.resume-link {
  text-decoration: none;
  color: white;
}
