.resume-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.resume {
  width: 100%;
  height: 100vh;
}

.resume-img {
  width: 900px;
  margin: auto;
}
