* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Arial", sans-serif;
  color: #333;
}

.about {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #004e98;
  color: #fff;
  padding: 50px 0;
}

.about h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 4vw;
  color: #f0f045;
  margin: 10px 0;
}

.about .prompt {
  width: 80%;
  font-size: 2.5vw;
  margin-top: 20px;
}

.prompt {
  width: 80%;
  font-size: 2.5vw;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.social-links a {
  display: inline-block;
  margin: 0 10px;
}

.prompt svg {
  font-size: 4vw;
  color: #fff;
}

.skills {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px 0;
}

.skills h1 {
  color: #333;
}

.skills .list {
  list-style: none;
  width: 80%;
  margin-top: 20px;
  padding: 0;
}

.skills .list li {
  margin-bottom: 15px;
}

.skills .list span {
  font-size: 1.5rem;
  text-align: center;
}

#headshot {
  width: 30%;
  max-width: 300px;
  border-radius: 50%;
  margin: 10px 0;
}

.info {
  width: 80%;
  margin-top: 20px;
  text-align: center;
  font-size: 2rem;
}

@media only screen and (max-width: 1200px) {
  #headshot {
    width: 300px;
  }

  .about h2 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .about h2 {
    font-size: 40px;
  }

  #headshot {
    width: 25%;
  }

  .about .prompt {
    margin-top: 10px;
    font-size: 20px;
    width: 80%;
  }

  .skills {
    text-align: center;
  }
  .list {
    padding: 0;
  }

  .skills h2 {
    font-size: 30px;
  }

  .info {
    font-size: 1.5rem;
  }
}
